@import "fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .vHidden {
    opacity: 0;
    visibility: hidden;
  }

  .clipPathEllipse1 {
    clip-path: ellipse(55% 150% at 50% -60%);

    @media (max-width: 768px) {
      clip-path: ellipse(70% 100% at 50% 0%);
    }

    @media (max-width: 551px) {
      clip-path: ellipse(80% 100% at 50% 0%);
    }

    @media (max-width: 440px) {
      clip-path: ellipse(100% 100% at 50% 0%);
    }
  }
  .clipPathWrapper{
    clip-path: polygon(0% 0%, 100% 0, 88% 100%, 0 100%);
  }
  .strokeStyles1 {
    stroke: rgba(255, 255, 255, 0.44);
    stroke-width: 10;
    stroke-linecap: round;
  }

  .strokeStyles2 {
    stroke-dasharray: 202px;
    stroke-dashoffset: calc(201px - (201px * var(--percent)) / 100);
    stroke: #ffffff;
  }

  .anim-transit {
    transition: 0.3s all ease 0s;
  }
  .anim-burger {
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .burgerLinesStyles {
    .line {
      fill: none;
      stroke-linecap: round;
      stroke-width: 5;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
          stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 5;
    }

    .line2 {
        stroke-dasharray: 60 60;
        stroke-width: 5;
    }

    .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 5;
    }
  }
  .burgerActiveLinesStyles {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 5;
    }

    .line2 {
        stroke-dasharray: 0 60;
        stroke-dashoffset: -30;
        stroke-width: 5;
    }

    .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 5;
    }
  }

  .clipPathPolygon {
    clip-path: polygon(11% 0, 100% 0%, 89% 100%, 0% 100%);
  }

  .tPerspective-1 {
    transform: perspective(500px) rotateX(-5deg);
  }

  .\!tPerspective-1 {
    transform: perspective(500px) rotateX(-5deg) !important;
  }

  .\!pb-0 {
    padding-bottom: 0;
  }

  .animTimeFunc1 {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .sliderBG1 {
    &::after {
      background: rgb(65, 182, 230);
      background: linear-gradient(276deg, #179bd1 43%, #41b6e6 43%);
    }
  }

  .customXScrollbar {
    &::-webkit-scrollbar-track {
      border: 3px solid #f2f6f8;
      background-color: #179bd1;
    }

    &::-webkit-scrollbar {
      height: 6px;
      background-color: #179bd1;

      &:hover,
      &:focus,
      &:active {
        background-color: #179bd1;
      }
    }

    &::-webkit-scrollbar-thumb {
      background-color: #179bd1;
      border-radius: 10px;
    }
  }
  
  .customYScrollbar {
    &::-webkit-scrollbar-track {
      border: 1px solid #F2F6F8;
      background-color: #179BD1;
    }

    &::-webkit-scrollbar {
        width: 3px;
        background-color: #179BD1;

        &:hover,
        &:focus,
        &:active {
            background-color: #179BD1;
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: #179BD1;
        border-radius: 5px;
    }
  }

  .customYScrollbarWhite {
    &::-webkit-scrollbar-track {
      border: 1px solid #179BD1;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar {
        width: 3px;
        background-color: #ffffff;

        &:hover,
        &:focus,
        &:active {
            background-color: #ffffff;
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 5px;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body._lock {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  outline: none;
}